import React, { useContext, useLayoutEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { ThemeContext, device } from '../providers/theme';
import styled from 'styled-components';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Block = styled.div`
  padding: 0 5vw;
  max-width: 62.5rem;
  margin: 2rem auto;

  .blog-post-content {
    h4 {
      font-size: 22px;
    }
  }

  a {
    font-weight: 400;
    text-decoration: underline;
  }

  h3 {
    font-size: 2rem;
    color: #000;
    @media ${device.mobile} {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 20px;
    font-weight: 100 !important;
    color: #0e1e25;
    line-height: 1.777;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin: 62px 0;
  @media ${device.mobile} {
    font-size: 2rem;
  }
`;

const Image = styled(Img)`
  margin: 62px 0 22px;
  min-height: 420px;
  img {
    margin: 0;
  }
`;

const Author = styled.span`
  font-size: 16px;
  font-style: italic;
  font-weight: 100 !important;
  color: #0e1e25;
  display: block;
  margin-bottom: 22px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 0;
`;

const ToggleArticle = styled.div`
  * {
    color: ${props => props.articleTheme};
  }

  .toggle-article {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 3px solid ${props => props.articleTheme};
    height: 30px;
    width: 30px;
    border-radius: 50%;

    &.next {
      margin-right: 0;
      margin-left: 8px;
    }

    &.previous {
      margin-right: 8px;
      margin-left: 0;
    }
  }
`;

const ArrowArticle = styled(Link)`
  text-decoration: none !important;
  display: flex;
  align-items: center;
`;

export default function BlogPostTemplate({ data, pageContext }) {
  const { next, previous } = pageContext;
  const { setTheme } = useContext(ThemeContext);
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const handleTheme = theme => {
    switch (theme) {
      case 'redWine':
        return '#722f37';
      case 'whiteWine':
        return '#d4c52d';
      case 'rose':
        return '#ee7272';
      case 'bubbles':
        return '#d4c52d';
      case 'dessertWine':
        return '#400000';
      default:
        return '#722f37';
    }
  };

  const nextArticle = () => (
    <ToggleArticle articleTheme={handleTheme(next?.frontmatter.type)}>
      {next && (
        <ArrowArticle to={next.frontmatter.path}>
          {next.frontmatter.title}
          <div className="toggle-article next">
            <FaArrowRight />
          </div>
        </ArrowArticle>
      )}
    </ToggleArticle>
  );

  const previousArticle = () => (
    <ToggleArticle articleTheme={handleTheme(previous?.frontmatter.type)}>
      {previous && (
        <ArrowArticle to={previous.frontmatter.path}>
          <div className="toggle-article previous">
            <FaArrowLeft />
          </div>
          {previous.frontmatter.title}
        </ArrowArticle>
      )}
    </ToggleArticle>
  );

  useLayoutEffect(() => {
    setTheme(frontmatter.type);
  }, []);
  return (
    <React.Fragment>
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.description ||
          'Læs andres oplevelser og anbefalinger af vin'
        }
      />
      <Layout title={frontmatter.title} withoutSeo>
        <Block>
          <Title>{frontmatter.title}</Title>
          {frontmatter?.image?.childImageSharp?.fluid && (
            <Image
              style={{ maxHeight: 320 }}
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          )}
          {frontmatter.author && (
            <Author>Skrevet af {frontmatter.author}</Author>
          )}
          <div
            className="blog-post-content {
            
              h4 {
                font-size: 22px;
              }
            }
            -content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Footer>
            {previousArticle()}
            {nextArticle()}
          </Footer>
        </Block>
      </Layout>
    </React.Fragment>
  );
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        author
        type
        image {
          childImageSharp {
            fluid(maxWidth: 370, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
